@supports (-webkit-touch-callout: none) {

    html,
    body {
        overscroll-behavior: none;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        overflow-x: hidden;
    }
}

body {
    background-color: #ec1442;
    color: #666666;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

.wrapper {
    max-width: 1200px;
    background-color: #f5f7fc;
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

@media all and (max-width: 900px) {
    .fill {
        padding: 0px !important;
    }
}

a {
    text-decoration: none;
}

.fill img {
    flex-shrink: 0;
    min-width: 100%;
    max-height: 100%;
}

.flyer {
    max-width: 570px;
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
}

.button {
    background-color: #be0f33;
    color: white;
    border: none;
    padding: 4px 40px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 0;
    font-size: smaller;
}

.button:hover {
    color: #666666;
    background-color: #f5f7fc;
    border: 1px solid #666666;
    padding: 4px 40px;
    text-decoration: none;
    text-transform: uppercase;
}

.button:disabled {
    opacity: 0.7;
    pointer-events: none;
    border: 1px solid #666666;
}

.text-container {
    background-color: #c4daee;
    color: black;
    padding: 20px 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.input-field {
    border: 1px solid black;
    border-radius: 0px;
}

.custom-hr {
    margin: 50px 50px 0px 50px;
    border: 1px solid #e0e2e6;
}

.description {
    color: #be0f33;
    font-size: small;
    cursor: pointer;
}

.spinner {
    height: 3rem;
    width: 3rem;
}

.text-primary {
    color: #be0f33 !important;
}

.ticket-disabled {
    color: rgb(156, 156, 156) !important;
    pointer-events: none;
}

.fs-medium {
    font-size: smaller;
}

.card {
    border-radius: 2px !important;
    background-color: #f0f2f7;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: #e0e2e6;
}

.card-header {
    background-color: #f0f2f7;
    font-weight: bold;
    border-bottom: 1px solid #e0e2e6;
}

.form-select {
    border-radius: 0;
}

.select-anmeldung {
    max-width: 300px;
}

.form-control {
    border-radius: 0;

}

.progress {
    border-radius: 0;
    height: 30px;
}

.progress-bar {
    background-color: #be0f33;
}

.ticket:hover,
.ticket-selected {
    background-color: #ecdfe2;
}

.tickets {
    padding: 0px 12px !important;
}

.payment {
    border: 1px solid #be0f33;
    padding: 8px;
    color: #be0f33;
    text-transform: uppercase;
    font-size: smaller;
}

.payment-selected {
    background-color: white;
    border: 1px solid #be0f33;
    padding: 8px;
    color: #be0f33;
    text-transform: uppercase;
    font-size: smaller;
}

.payment-logo {
    max-width: 80px !important;
}

.payment-twint {
    max-width: 40px !important;
}